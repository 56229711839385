import {ApiErrorBody} from "@/core/Error";

export type FetchResponseMetadata = {
  lastUpdated?: string;
};

export type FetchResponse<T> = [T, FetchResponseMetadata | undefined];

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const fetcher = async (...args) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const response = await fetch(...args);
  if (!response.ok) {
    let errorMessage =
      "The server encountered an internal error or misconfiguration and was unable to complete your request.";

    try {
      const errorBody: ApiErrorBody = await response.json();
      errorMessage = errorBody?.error?.message;
    } catch (_) {}

    throw new Error(errorMessage);
  }

  return response.json();
};
