import {Space, Typography} from "antd";
import React from "react";

type PageHeaderProps = {
  title?: string;
  tags?: React.ReactNode;
  center?: React.ReactNode;
  extra?: React.ReactNode;
  children?: React.ReactNode;
};

const PageHeader: React.FC<PageHeaderProps> = (props) => {
  const {title, tags, center, extra, children} = props;

  return (
    <div style={{margin: 20}}>
      {(title || tags || center || extra) && (
        <div style={{display: "flex", justifyContent: "space-between", marginBottom: 16}}>
          <Space>
            <div>
              <Typography.Title style={{margin: 0}} level={4}>
                {title}
              </Typography.Title>
            </div>
            {tags && <div>{tags}</div>}
          </Space>
          {center && <div>{center}</div>}
          {extra && <div>{extra}</div>}
        </div>
      )}
      <div>{children}</div>
    </div>
  );
};

export default PageHeader;
