import React from "react";

import Dashboard from "@/components/Dashboard";
import PageHeader from "@/components/PageHeader";

const Home = () => {
  return (
    <PageHeader>
      <Dashboard />
    </PageHeader>
  );
};

export default Home;
