import {useQuery} from "@tanstack/react-query";

import type {StatisticsGeneral} from "@/modules/statistics/Types";
import {fetcher, FetchResponse} from "@/utils/fetchers";

export const StatisticsQueryKey = {
  General: ["data", "statistics", "general"],
};

const useStatisticsGeneral = () =>
  useQuery<FetchResponse<StatisticsGeneral>, Error>(StatisticsQueryKey.General, () =>
    fetcher("/api/statistics/general")
  );

export const StatisticsQuery = {
  useStatisticsGeneral,
};
