import {InfoCircleOutlined, ProjectOutlined, TeamOutlined} from "@ant-design/icons";
import {Alert, Card, Col, Row, Space, Statistic, Tooltip, Typography} from "antd";
import classNames from "classnames";
import {NextPage} from "next";
import React from "react";
import {createUseStyles} from "react-jss";

import {StatisticsQuery} from "@/modules/statistics";

const useStyles = createUseStyles({
  container: {},
  card: {
    width: "200px",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardInfo: {
    fontSize: 12,
    color: "lightgray",
  },
  valueIcon: {
    paddingRight: 15,
  },
});

const Dashboard: NextPage<React.HTMLAttributes<HTMLDivElement>> = (props) => {
  const styles = useStyles();
  const {className} = props;

  const {data: generalData, error: generalError, isLoading: generalIsLoading} = StatisticsQuery.useStatisticsGeneral();

  const [generalValues] = generalData ?? [];

  const fetchError = generalError;
  const fetchIsLoading = generalIsLoading;

  return (
    <>
      {fetchError && <Alert type="error" showIcon message="Failed to load" description={fetchError.message} />}
      {!fetchError && (
        <div className={classNames([styles.container, className])}>
          <Row justify="start" gutter={[16, 16]}>
            <Col>
              <Card className={styles.card} loading={fetchIsLoading}>
                <Statistic
                  loading={fetchIsLoading}
                  title={
                    <div className={styles.cardHeader}>
                      <Typography.Text type="secondary">Headcount</Typography.Text>
                      <Tooltip
                        title={
                          <Space direction="vertical">
                            Bamboo active employees.
                            <span>· Forecast: {generalValues?.forecast.users.count}</span>
                            <span>· Harvest: {generalValues?.harvest.users.count}</span>
                            <span>· SkillsBase: {generalValues?.skillsBase.people.count}</span>
                          </Space>
                        }>
                        <InfoCircleOutlined className={styles.cardInfo} />
                      </Tooltip>
                    </div>
                  }
                  prefix={<TeamOutlined className={styles.valueIcon} />}
                  value={generalValues?.bamboo.employees.count}
                />
              </Card>
            </Col>
            <Col>
              <Card className={styles.card} loading={fetchIsLoading}>
                <Statistic
                  loading={fetchIsLoading}
                  title={
                    <Space className={styles.cardHeader}>
                      <Typography.Text type="secondary">Active Projects</Typography.Text>
                      <Tooltip
                        title={
                          <Space direction="vertical">
                            Forecast active projects.
                            <span>· Harvest: {generalValues?.harvest.projects.count}</span>
                            <span>· Jira: {generalValues?.jira.projects.count}</span>
                          </Space>
                        }>
                        <InfoCircleOutlined className={styles.cardInfo} />
                      </Tooltip>
                    </Space>
                  }
                  prefix={<ProjectOutlined className={styles.valueIcon} />}
                  value={generalValues?.forecast.projects.count}
                />
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default Dashboard;
